import curry from 'lodash/curry';
import store from 'resources/store';
import * as fromGarage from 'resources/garage/garage.selectors';
import polyglot from 'services/localization';
import { getAustrianNumber } from './number';

const getCountry = () => fromGarage.getCountry(store.getState());
const getSign = () => (['CH', 'LI'].includes(getCountry()) ? 'CHF' : '€');
const price = (value, currency = getSign()) => {
  return `${getAustrianNumber((value || 0).toFixed(2), getCountry())} ${currency}`;
};
const priceNoDecimal = (value) => {
  return `${getAustrianNumber((value || 0).toFixed(0), getCountry())} ${getSign()}`;
};
const curriedPriceNoDecimal = curry(priceNoDecimal);
const curriedPrice = curry(price);
const getVatInfo = (short = false) => polyglot.t(`garages.netOrGross${short ? '.short' : ''}.${fromGarage.getNetOrGross(store.getState())}`);

export {
  getSign, curriedPrice, curriedPriceNoDecimal, getCountry, getVatInfo,
};
